import $ from 'jquery'

function generatePromodo (orderItems = [], currency, step) {
  function findCategory (id) {
    switch (id) {
      case '1':
        return 'Prescription Glasses'
      case '2':
        return 'Prescription Sunglasses'
      case '3':
        return 'Designer Sunglasses'
      case '4':
        return 'Accessories'
      case '5':
        return 'Contact Lenses'
      case '6':
        return 'Goggles'
    }
  }

  let products = orderItems.map(orderItem => {
    return {
      'name': orderItem.item_description,
      'id': orderItem.item.item_number,
      'price': orderItem.price,
      'brand': orderItem.item.brand_name,
      'category': findCategory(orderItem.tab_id),
      'quantity': orderItem.quantity
    }
  })
  let dataObject = JSON.stringify({
    'ecommerce': {
      'currencyCode': currency,
      'checkout': {
        'actionField': {'step': step},
        'products': products
      }
    },
    'event': 'gtm-ee-event',
    'gtm-ee-event-category': 'Enhanced Ecommerce',
    'gtm-ee-event-action': 'Checkout Step ' + step,
    'gtm-ee-event-non-interaction': 'False'
  })

  let promodo = $(`body script#promodo${step}Step.promodo`)
  if (promodo.length > 0) {
    promodo.remove()
  }
  promodo = $(`<script id="promodo${step}Step" class="promodo">
  window.dataLayer = window.dataLayer || [];
  dataLayer.push(${dataObject})
</script>`)
  $('body').append($(promodo))
}
function cleanPromodo () {
  $('body script.promodo').remove()
}

export { generatePromodo, cleanPromodo }
