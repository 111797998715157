import { mapState, mapActions } from 'vuex'
import config from '@/../config'

export default {
  name: 'CheckoutLogin',
  data () {
    return {
      email: '',
      password: '',
      error: '',
      host: config.integrationHost
    }
  },
  methods: {
    onSubmit (e) {
      e.preventDefault()
      let self = this
      this.$store.dispatch('onSubmitPopupLogin', this.$router)
        .then(res => {
          this.$emit('modal-close')
          if (res.data.service[0] === 'Malfunction login params' || res.data.service[0] === 'Bad email or password') {
            self.error = 'Please enter correct email/password'
          } else {
            self.error = res.data.service[0]
          }
        })
    },
    forgotPassword () {
      this.$emit('modal-close')
      // this.$router.push(this.$link('/auth/request_new_password/'))
      document.location.href = config.integrationHost + '/auth/request_new_password/'
    },
    ...mapActions({
      onChange: 'onChangeForm'
    })
  },
  computed: {
    ...mapState({
      data (state) {
        const {formData: {popupLogin: {...data} = {}}} = state
        this.email = data.email
        this.password = data.password

        return data
      },
      meta: (state) => ({
        ...state.formMeta['popupLogin']
      }),
      user: (state) => ({
        ...state.user
      })
    })
  },
  watch: {
    meta () {
      if (this.user.user_number) {
      }
    }
  }
}
